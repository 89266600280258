import React, { useState } from "react";
import Menu from "../../components/Menu";
import { useNavigate } from "react-router-dom";
import api from '../../services/apiFormData'
import Swal from 'sweetalert2'

import './Medicines.css'
import LoadingBlock from "../../components/LoadingBlock";
import FooterAdd from "../../components/FooterAdd";
import MedicinePills from '../../assets/img/pilulas-black.svg'
import { Form } from "react-bootstrap";

export default function MedicinesAdd() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})

    const [name, setName] = useState("")
    const [registerMS, setRegisterMS] = useState("")
    const [descriptionDCB, setDescriptionDCB] = useState("")
    const [codeDCB, setCodeDCB] = useState("")

    const [presentation, setPresentation] = useState("")
    const [posologia, setPosologia] = useState("")
    const [unit, setUnit] = useState("")
    const [isSpecial, setIsSpecial] = useState(false)
    const [withoutMedication, setWithoutMedication] = useState(false);
    const [externalCode, setExternalCode] = useState("")

    const onSubmit = (ev) => {
        ev.preventDefault()
        var errors = {
            name: name.replaceAll(' ', '') === '' ? true : false,
            registerMS: registerMS.replaceAll(' ', '') === '' ? true : false,
            descriptionDCB: descriptionDCB.replaceAll(' ', '') === '' ? true : false,
            //prescriptionType: prescriptionType === "" ? true : false,
            codeDCB: codeDCB.replaceAll(' ', '') === "" ? true : false,
            presentation: presentation.replaceAll(' ', '') === "" ? true : false,
            unit: unit.replaceAll(' ', '') === "" ? true : false,
            posologia: posologia.replaceAll(' ', '') === "" ? true : false,
            externalCode: externalCode.replaceAll(' ', '') === "" ? true : false
        }
        setError(errors)
        var validations = true

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && errors[key] === true) {
                validations = false;
            }
        }

        if (validations) {
            var form = new FormData();

            form.set('name', name);
            form.set('code_ms', registerMS);
            form.set('dcb_code', codeDCB);
            form.set('dcb_description', descriptionDCB);
            form.set('dosage', posologia);
            form.set('presentation', presentation);
            form.set('type', isSpecial ? 'Especial' : 'Normal');
            form.set('unit', unit);
            form.set('unit_price', 1);
            form.set('box_price', 5);
            form.set('stock_quantity', 10);
            form.set('image', null);

            form.set('external_code', externalCode);
            form.set('without_medication', withoutMedication);

            //integration code

            setLoading(true)

            api.post("/api/pharmacydrugs", form).then((response) => {
                navigate("/medicines/")

                Swal.fire({
                    title: "Medicamento cadastrado com sucesso",
                    icon: 'success',
                    confirmButtonColor: '#56CCF2',
                })
            }).catch((error) => {
                console.error(error)
                Swal.fire({
                    title: "Medicamento não cadastrado",
                    text: error.message,
                    icon: 'error',
                    confirmButtonColor: '#56CCF2',

                })
            }).finally(() => {
                setLoading(false)
            })
        }
    }
    const screenTela =
        <>
            {loading && <LoadingBlock />}
            <form onSubmit={onSubmit}>
                <div className='screenContainer edit-mode'>
                    <h1 className="screenTitle">
                        <img style={{ width: '40px' }} alt="svg" src={MedicinePills} /> <span className="name-screen-title" >
                            Novo Medicamento
                        </span>
                    </h1>

                    <div className="row">
                        <h4 className="screenSubTitle">Dados Básicos</h4>

                        <div className="col-12 col-lg-6 col-xl-3 col-xxl-4 row gap-1" style={{ padding: '0px', margin: '-1px' }}>
                            <div className="col-12">
                                <label htmlFor="nameInput" className="form-label-input">Nome*</label>
                                <input id="nameInput" className="form-control" value={name} type="text" name="name" placeholder="Nome" onChange={value => setName(value.target.value.toUpperCase())} />
                                {error.name && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12 col-lg-6 col-xl-6">
                                <label className="form-label-input">Unidade*</label>
                                <select className="form-select" onChange={value => setUnit(value.target.value)} defaultValue={'DEFAULT'}>
                                    <option value="DEFAULT" disabled hidden>Selecione a unidade</option>
                                    <option value="Und">Unidade</option>
                                    <option value="Cx">Caixa</option>
                                    <option value="Frs">Frasco</option>
                                    <option value="ml">ml</option>
                                    <option value="mg">mg</option>
                                    <option value="Kg">Kg</option>
                                    <option value="g">g</option>
                                </select>
                                {error.unit && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12">
                                <label htmlFor="codeDCBInput" inputMode="numeric" className="form-label-input">Código D.C.B.*</label>
                                <input id="codeDCBInput" className="form-control" value={codeDCB} type="text" name="codeDCB" placeholder="Código D.C.B." onChange={value => setCodeDCB(value.target.value.replace(/\D/g, ''))} />
                                {error.codeDCB && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12">
                                <label htmlFor="registerMSInput" className="form-label-input">Registro Ministério da Saúde*</label>
                                <input id="registerMSInput" inputMode="numeric" className="form-control" value={registerMS} onChange={value => setRegisterMS(value.target.value.replace(/\D/g, ''))} type="text" name="registerMS" placeholder="Registro Ministério da Saúde" />
                                {error.registerMS && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-4 row gap-1" style={{ padding: '0px', margin: '-1px' }}>
                            <div className="col-12">
                                <label htmlFor="presentationInput" className="form-label-input">Apresentação*</label>
                                <input id="presentationInput" className="form-control" value={presentation} onChange={value => setPresentation(value.target.value.toUpperCase())} type="text" name="presentation" placeholder="Apresentação" />
                                {error.presentation && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12 col-lg-8 col-xl-8 d-flex align-items-end justify-content-between" style={{ paddingTop: 13.5 }}>
                                <div>
                                    <label className="form-label-input">Tipo de Medicamento*</label>
                                    <div className="mt-1">
                                        <div className="form-check form-check-inline">
                                            <input onChange={() => {
                                                setIsSpecial(!isSpecial)
                                            }} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={!isSpecial} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Normal</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={() => {
                                                setIsSpecial(!isSpecial)
                                            }} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={isSpecial} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Especial</label>
                                        </div>
                                    </div>
                                    {error.prescriptionType && <span className="error" >Campo Inválido</span>}
                                </div>

                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        id="missing-medicine"
                                        label="Em falta"
                                        checked={withoutMedication}
                                        onChange={() => setWithoutMedication(!withoutMedication)}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <label htmlFor="descriptionDCBInput" className="form-label-input">Descrição D.C.B.*</label>
                                <input id="descriptionDCBInput" className="form-control" value={descriptionDCB} onChange={value => setDescriptionDCB(value.target.value)} type="text" name="descriptionDCB" placeholder="Descrição D.C.B." />
                                {error.descriptionDCB && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12">
                                <label htmlFor="externalCodeInput" className="form-label-input">Código de integração*</label>
                                <input id="externalCodeInput" className="form-control" value={externalCode} onChange={value => setExternalCode(value.target.value)} type="text" name="externalCode" placeholder="Código de integração" />
                                {error.externalCode && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>

                        <div className="col-12 col-xl-4 col-xxl-4 row" style={{ padding: '0px', margin: '-1px' }}>
                            <div className="col-12">
                                <label htmlFor="posologiaInput" className="form-label-input">Posologia*</label>
                                <textarea style={{ height: 'auto' }} rows={11} id="posologiaInput" className="form-control" value={posologia} type="text" name="posologia" placeholder="Posologia" onChange={value => setPosologia(value.target.value.toUpperCase())} />
                                {error.posologia && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <FooterAdd />
            </form>
        </>
    return (
        <Menu screen={screenTela} />
    )
}