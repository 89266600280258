import { useState, useEffect } from "react";
import Menu from "../../components/Menu";
import { useParams, useNavigate } from "react-router-dom";
import api from '../../services/api';
import Swal from 'sweetalert2'

import './Medicines.css'
import MedicinePills from '../../assets/img/pilulas-black.svg';
import Clear from '../../assets/img/remove.svg'
import LoadingBlock from "../../components/LoadingBlock";
import ModalYesNo from "../../components/ModalYesNo";
import FooterEdit from "../../components/FooterEdit";
import { Form } from "react-bootstrap";

export default function MedicinesEdit() {
    let { id } = useParams();
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})

    const [name, setName] = useState("")
    const [registerMS, setRegisterMS] = useState("")
    const [descriptionDCB, setDescriptionDCB] = useState("")
    const [codeDCB, setCodeDCB] = useState("")

    const [presentation, setPresentation] = useState("")
    const [posologia, setPosologia] = useState("")
    const [isSpecial, setIsSpecial] = useState();
    const [price, setPrice] = useState(null);
    const [quant, setQuant] = useState(null);
    const [und, setUnd] = useState('');
    const [withoutMedication, setWithoutMedication] = useState(false);
    const [externalCode, setExternalCode] = useState("")

    useEffect(() => {
        api.get('/api/pharmacydrugs/' + id).then((response) => {
            const data = response.data.data

            setCodeDCB(data.dcb_code)
            setDescriptionDCB(data.dcb_description)
            setName(data.name)
            setPosologia(data.dosage)
            setPresentation(data.presentation)
            setRegisterMS(data.code_ms)
            setIsSpecial(data.type === 'Normal' ? false : true)
            setPrice(data.unit_price)
            setUnd(data.unit)
            setQuant(data.stock_quantity)
            setExternalCode(data.external_code)
            setWithoutMedication(data.without_medication)
        }).catch((error) => {
            console.error('Error fetching agreements:', error);
        }).finally(() => {

        })
    }, [id])

    const onDelete = () => {
        setShowModal(false)
        setLoading(true)
        api.delete("/api/pharmacydrugs/" + id).then((response) => {
            Swal.fire({
                title: "Medicamento excluído com sucesso",
                icon: 'success',
                confirmButtonColor: '#56CCF2',
            })
            setTimeout(() => {
                Swal.close()
                setTimeout(() => {
                    navigate('/medicines')
                }, 750);
            }, 1500);
        }).catch((error) => {
            console.error(error)
            Swal.fire({
                title: "Medicamento não excluído",
                text: error.message,
                icon: 'error',
                confirmButtonColor: '#56CCF2',

            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        var errors = {
            name: name.replaceAll(' ', '') === '' ? true : false,
            registerMS: registerMS.replaceAll(' ', '') === '' ? true : false,
            descriptionDCB: descriptionDCB.replaceAll(' ', '') === '' ? true : false,
            //prescriptionType: prescriptionType === "" ? true : false,
            codeDCB: codeDCB.replaceAll(' ', '') === "" ? true : false,
            presentation: presentation.replaceAll(' ', '') === "" ? true : false,
            posologia: posologia.replaceAll(' ', '') === "" ? true : false,
            externalCode: externalCode.replaceAll(' ', '') === "" ? true : false
        }
        setError(errors)
        var validations = true

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && errors[key] === true) {
                validations = false;
            }
        }

        if (validations) {
            var form = new FormData();

            form.set('id', id);
            form.set('name', name);
            form.set('code_ms', registerMS);
            form.set('dcb_code', codeDCB);
            form.set('dcb_description', descriptionDCB);
            form.set('presentation', presentation);
            form.set('dosage', posologia);
            form.set('type', isSpecial ? 'Especial' : 'Normal');
            form.set('unit', und);
            form.set('unit_price', 1);
            form.set('box_price', 5);
            form.set('stock_quantity', 10);
            form.set('image', null);

            form.set('external_code', externalCode);
            form.set('without_medication', withoutMedication);

            setLoading(true)

            api.put("/api/pharmacydrugs", form).then((response) => {
                navigate("/medicines")

                Swal.fire({
                    title: "Medicamento editado com sucesso",
                    icon: 'success',
                    confirmButtonColor: '#56CCF2',
                })
            }).catch((error) => {
                console.error(error)
                Swal.fire({
                    title: "Medicamento não editado",
                    text: error.message,
                    icon: 'error',
                    confirmButtonColor: '#56CCF2',

                })
            }).finally(() => {
                setLoading(false)
            })
        }
    }
    const screenTela =
        <>
            {loading && <LoadingBlock />}
            <ModalYesNo show={showModal} setShow={setShowModal} affirnName={"Deletar"} negName={"Voltar"} afirmativeFunc={onDelete} message={"Ao excluir um registro não será possivel recuperar"} title={"Tem certeza?"} imgSVG={Clear} />

            <form onSubmit={onSubmit}>
                <div className='screenContainer edit-mode'>
                    <div className="containerTitle">
                        <h1 className="screenTitle">
                            <img style={{ width: '40px' }} alt="svg" src={MedicinePills} /> <span className="name-screen-title" >
                                Editar Medicamento
                            </span>
                        </h1>

                        <div>
                            {price !== null && <h2 style={{ textAlign: 'right', fontSize: '48px' }} className="screenTitle leftTile">R$ {price.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</h2>}
                            {
                                quant !== null && und !== '' && <h2 style={{ textAlign: 'right', fontSize: '48px' }} className="screenTitle leftTile"> {quant + ' ' + und}</h2>
                            }
                        </div>

                    </div>
                    <div className="row">
                        <h4 className="screenSubTitle">Dados Básicos</h4>

                        <div className="col-12 col-lg-6 col-xl-3 col-xxl-4 row gap-1" style={{ padding: '0px', margin: '-1px' }}>
                            <div className="col-12">
                                <label htmlFor="nameInput" className="form-label-input">Nome*</label>
                                <input id="nameInput" className="form-control" value={name} type="text" name="name" placeholder="Nome" onChange={value => setName(value.target.value.toUpperCase())} />
                                {error.name && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12 col-lg-5 col-xl-5">
                                <label className="form-label-input">Unidade*</label>
                                <select className="form-select" onChange={value => setUnd(value.target.value)} value={und}>
                                    <option disabled hidden>Selecione a unidade</option>
                                    <option value="Und">Unidade</option>
                                    <option value="Cx">Caixa</option>
                                    <option value="Frs">Frasco</option>
                                    <option value="ml">ml</option>
                                    <option value="mg">mg</option>
                                    <option value="Kg">Kg</option>
                                    <option value="g">g</option>
                                </select>
                            </div>

                            <div className="col-12">
                                <label htmlFor="codeDCBInput" inputMode="numeric" className="form-label-input">Código D.C.B.*</label>
                                <input id="codeDCBInput" className="form-control" value={codeDCB} type="text" name="codeDCB" placeholder="Código D.C.B." onChange={value => setCodeDCB(value.target.value.replace(/\D/g, ''))} />
                                {error.codeDCB && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12">
                                <label htmlFor="registerMSInput" className="form-label-input">Registro Ministério da Saúde*</label>
                                <input id="registerMSInput" inputMode="numeric" className="form-control" value={registerMS} onChange={value => setRegisterMS(value.target.value.replace(/\D/g, ''))} type="text" name="registerMS" placeholder="Registro Ministério da Saúde" />
                                {error.registerMS && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-5 col-xxl-4 row gap-1" style={{ padding: '0px', margin: '-1px' }}>

                            <div className="col-12">
                                <label htmlFor="presentationInput" className="form-label-input">Apresentação*</label>
                                <input id="presentationInput" className="form-control" value={presentation} onChange={value => setPresentation(value.target.value.toUpperCase())} type="text" name="presentation" placeholder="Apresentação" />
                                {error.presentation && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12 col-lg-8 col-xl-8 d-flex align-items-end justify-content-between" style={{ paddingTop: 17 }}>
                                <div>
                                    <label className="form-label-input">Tipo de Medicamento*</label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={() => {
                                                setIsSpecial(!isSpecial)
                                            }} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={!isSpecial} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Normal</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={() => {
                                                setIsSpecial(!isSpecial)
                                            }} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={isSpecial} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Especial</label>
                                        </div>
                                    </div>
                                    {error.prescriptionType && <span className="error" >Campo Inválido</span>}
                                </div>

                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        id="missing-medicine"
                                        label="Em falta"
                                        checked={withoutMedication}
                                        onChange={() => setWithoutMedication(!withoutMedication)}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <label htmlFor="descriptionDCBInput" className="form-label-input">Descrição D.C.B.*</label>
                                <input id="descriptionDCBInput" className="form-control" value={descriptionDCB} onChange={value => setDescriptionDCB(value.target.value)} type="text" name="descriptionDCB" placeholder="Descrição D.C.B." />
                                {error.descriptionDCB && <span className="error" >Campo Inválido</span>}
                            </div>

                            <div className="col-12">
                                <label htmlFor="externalCodeInput" className="form-label-input">Código de integração*</label>
                                <input id="externalCodeInput" className="form-control" value={externalCode} onChange={value => setExternalCode(value.target.value)} type="text" name="externalCode" placeholder="Código de integração" />
                                {error.externalCode && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>

                        <div className="col-12 col-xl-4 col-xxl-4 row" style={{ padding: '0px', margin: '-1px' }}>
                            <div className="col-12">
                                <label htmlFor="posologiaInput" className="form-label-input">Posologia*</label>
                                <textarea style={{ height: 'auto' }} rows={11} id="posologiaInput" className="form-control" value={posologia} type="text" name="posologia" placeholder="Posologia" onChange={value => setPosologia(value.target.value.toUpperCase())} />
                                {error.posologia && <span className="error" >Campo Inválido</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <FooterEdit navigateRoute={"/medicines"} onClickDel={() => {
                    setShowModal(true)
                }} />
            </form>
        </>

    return (
        <Menu screen={screenTela} />
    )
}